<template>
  <div>
    <v-row>
      <v-col cols="12" md="9">
        <div class="mb-5">
          <SelectSuggestGoodsWithPrice
            @onSelect="selectItem"
            :select-items="selectItems"
            is-pos
          />
        </div>

        <div v-if="selectItems.length > 0">
          <v-card
            v-for="gKey in groupKeys"
            :key="`gr_${gKey}`"
            class="mb-5 card-custom-shadow"
          >
            <v-card-title>
              <v-row dense>
                <v-col
                  cols="12"
                  md="4"
                  class="font-weight-medium error--text text-center text-md-left"
                  >{{ $t("labels.pos") }}: {{ gKey }}</v-col
                >
                <v-col cols="6" md="4">
                  <div
                    class="font-weight-medium primary--text text-left text-md-center"
                  >
                    {{ $t("labels.total_price") }}:
                    <span>{{ formatNumber(totalPrice(gKey)) }} đ</span>
                  </div>
                </v-col>
                <v-col cols="6" md="4">
                  <div class="font-weight-medium error--text text-right">
                    {{ $t("labels.cod") }}: {{ formatNumber(cod(gKey)) }} đ
                    <span v-if="orders[gKey].receiver_pay_for_delivery_cost">
                      + {{ $t("labels.delivery_cost") }}</span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card-title>

            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    v-model="orders[gKey].tvc"
                    :items="deliveryTypeOptions"
                    :label="$t('labels.delivery_type')"
                    outlined
                    dense
                    hide-details
                    class="c-input-small"
                    @change="onTvcChange(gKey)"
                  ></v-autocomplete>
                </v-col>
                <template v-if="!orders[gKey].tvc">
                  <v-col cols="12" md="2">
                    <v-autocomplete
                      v-model="orders[gKey].receiver_pay_for_delivery_cost"
                      :items="payDeliveryCostOptions"
                      :label="$t('labels.delivery_payment_method')"
                      outlined
                      dense
                      hide-details
                      class="c-input-small"
                      @change="onReceiverPayCostChange(gKey)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="2">
                    <input-number
                      v-model.number="orders[gKey].add_ship_cost"
                      :disabled="
                        orders[gKey].receiver_pay_for_delivery_cost === 1
                      "
                      type="number"
                      :label="$t('labels.ship_tp')"
                      outlined
                      dense
                      hide-details
                      class="c-input-small"
                      @input="onCostAndPrepayChange"
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-checkbox
                      v-model="orders[gKey].delivery_check_goods"
                      class="c-input-small"
                      label="Xem hàng"
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-checkbox
                      v-model="orders[gKey].delivery_partial"
                      class="c-input-small"
                      label="Giao 01 phần"
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-checkbox
                      v-model="orders[gKey].delivery_return_required"
                      class="c-input-small"
                      label="Đổi hàng"
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </template>

                <template v-else>
                  <v-col cols="12" md="2">
                    <v-autocomplete
                      v-model="orders[gKey].id_delivery_company"
                      :items="deliveryCompanyOptions"
                      :label="$t('labels.delivery_company')"
                      class="c-input-small"
                      outlined
                      dense
                      hide-details
                      @change="deliveryChange(gKey)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="2">
                    <input-number
                      v-model.number="orders[gKey].add_ship_cost"
                      :disabled="
                        orders[gKey].receiver_pay_for_delivery_cost === 1
                      "
                      type="number"
                      :label="$t('labels.ship_tp')"
                      outlined
                      dense
                      hide-details
                      class="c-input-small"
                      @input="onCostAndPrepayChange"
                    />
                  </v-col>
                  <v-col cols="12" md="6" v-if="orders[gKey].tvc">
                    <v-text-field
                      v-model="orders[gKey].delivery_order_id"
                      :label="$t('labels.delivery_order_id')"
                      outlined
                      dense
                      hide-details
                      class="c-input-small"
                    ></v-text-field>
                  </v-col>
                </template>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="2">
                  <v-text-field
                    class="c-input-small"
                    v-model="orders[gKey].customer_order_id"
                    :label="`${$t('labels.customer_order_id')}`"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <input-number
                    class="c-input-small input-error"
                    v-model.number="orders[gKey].prepay"
                    :label="`${$t('labels.order_prepay')}`"
                    outlined
                    dense
                    hide-details
                    @input="onCostAndPrepayChange"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    class="c-input-small input-error"
                    v-model="orders[gKey].id_customer_bank_account"
                    :label="`${$t('labels.order_bank_account')}`"
                    :items="bankAccountOptions"
                    outlined
                    dense
                    clearable
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="2">
                  <v-file-input
                    accept="image/*"
                    v-model="orders[gKey].file_bill"
                    @change="onUploadBill"
                    @click="onUploading(gKey)"
                    :label="$t('labels.bill')"
                    :placeholder="$t('labels.bill')"
                    dense
                    multiple
                    outlined
                    hide-details
                    class="c-input-small input-error c-input-file"
                    append-icon="mdi-paperclip"
                    prepend-icon=""
                  ></v-file-input>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    class="c-input-small"
                    v-model="orders[gKey].note"
                    :label="`${$t('labels.note')}`"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <div class="mt-5">
                <v-simple-table class="table-padding-2-no-top">
                  <template v-slot:default>
                    <thead class="v-data-table-header">
                      <tr>
                        <th
                          class="text-center vertical-align-middle error--text"
                        >
                          {{ $t("labels.product") }}
                        </th>
                        <th class="text-center vertical-align-middle">
                          {{ $t("labels.name") }}
                        </th>
                        <th class="text-center vertical-align-middle">
                          {{ $t("labels.goods_description") }}
                        </th>
                        <th class="text-center vertical-align-middle">
                          {{ $t("labels.size") }}
                        </th>
                        <th
                          class="text-center vertical-align-middle"
                          style="width: 75px"
                        >
                          {{ $t("labels.available_quantity_1") }}
                        </th>
                        <th
                          class="text-center vertical-align-middle"
                          style="width: 75px"
                        >
                          {{ $t("labels.quantity") }}
                        </th>
                        <th
                          class="text-center vertical-align-middle"
                          style="width: 90px"
                        >
                          {{ $t("labels.price_0") }}
                        </th>
                        <th
                          class="text-center vertical-align-middle"
                          style="width: 75px"
                        >
                          {{ $t("labels.type") }}
                        </th>
                        <th
                          class="text-center vertical-align-middle"
                          style="width: 85px"
                        >
                          Giảm giá
                        </th>
                        <th
                          class="text-center vertical-align-middle"
                          style="width: 100px"
                        >
                          Giá trị
                        </th>
                        <th
                          class="text-center vertical-align-middle"
                          style="width: 75px"
                        >
                          <v-btn
                            v-if="groupGoodsItems(gKey).length > 0"
                            color="warning"
                            x-small
                            style="min-width: 20px; height: 20px; padding: 0"
                            @click="resetSelected(gKey)"
                          >
                            <v-icon style="font-size: 14px">mdi-restore</v-icon>
                          </v-btn>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="groupGoodsItems(gKey).length > 0">
                      <tr
                        v-for="item in groupGoodsItems(gKey)"
                        :key="`s${item.id}`"
                        class="text-center"
                      >
                        <td style="width: 105px">
                          {{ item.customer_goods_barcode }}
                        </td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.size }}</td>
                        <td>{{ formatNumber(item.available) }}</td>
                        <td style="width: 60px">
                          <v-text-field
                            v-model.number="item.quantity"
                            class="c-input-number-xs"
                            type="number"
                            @keyup="quantityAndDiscountChange"
                            dense
                            single-line
                            outlined
                            hide-details
                          ></v-text-field>
                        </td>
                        <td>{{ formatNumber(item[item.select_price]) }}</td>
                        <td>{{ priceType[item.select_price] }}</td>
                        <td>
                          <v-select
                            v-model="item.discount_type"
                            :items="discountTypeOptions"
                            class="c-input-small"
                            outlined
                            dense
                            hide-details
                            @change="quantityAndDiscountChange"
                            :disabled="
                              !checkPermission(['order_create_select_discount'])
                            "
                          ></v-select>
                        </td>
                        <td>
                          <template
                            v-if="
                              item.discount_type &&
                              checkPermission(['order_create_select_discount'])
                            "
                          >
                            <v-text-field
                              v-if="item.discount_type === 1"
                              v-model.number="item.discount_value"
                              max="100"
                              class="c-input-small"
                              type="number"
                              @keyup="quantityAndDiscountChange"
                              dense
                              single-line
                              outlined
                              hide-details
                            ></v-text-field>
                            <input-number
                              v-else
                              v-model.number="item.discount_value"
                              type="number"
                              @input="quantityAndDiscountChange"
                              outlined
                              dense
                              hide-details
                              class="c-input-small"
                            />
                          </template>
                        </td>
                        <td>
                          <v-btn
                            color="grey lighten-2"
                            x-small
                            style="min-width: 20px; height: 20px; padding: 0"
                            @click="removeSelected(item)"
                          >
                            <v-icon style="font-size: 14px">mdi-close</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td
                          colspan="9"
                          class="text-center vertical-align-middle"
                        >
                          {{ $t("labels.not_yet_select_goods") }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-simple-table class="table-padding-2-no-top">
                  <template v-slot:default>
                    <thead class="v-data-table-header">
                      <tr>
                        <th
                          class="text-center vertical-align-middle error--text"
                        >
                          {{ $t("labels.combo") }}
                        </th>
                        <th class="text-center vertical-align-middle">
                          {{ $t("labels.goods") }}
                        </th>
                        <th
                          class="text-center vertical-align-middle"
                          style="width: 75px"
                        >
                          {{ $t("labels.available_quantity_1") }}
                        </th>
                        <th
                          class="text-center vertical-align-middle"
                          style="width: 75px"
                        >
                          {{ $t("labels.quantity") }}
                        </th>
                        <th
                          class="text-center vertical-align-middle"
                          style="width: 90px"
                        >
                          {{ $t("labels.price_0") }}
                        </th>
                        <th
                          class="text-center vertical-align-middle"
                          style="width: 75px"
                        >
                          {{ $t("labels.type") }}
                        </th>
                        <th
                          class="text-center vertical-align-middle"
                          style="width: 85px"
                        >
                          Giảm giá
                        </th>
                        <th
                          class="text-center vertical-align-middle"
                          style="width: 100px"
                        >
                          Giá trị
                        </th>
                        <th
                          class="text-center vertical-align-middle"
                          style="width: 75px"
                        >
                          <v-btn
                            v-if="groupComboItems(gKey).length > 0"
                            color="warning"
                            x-small
                            style="min-width: 20px; height: 20px; padding: 0"
                            @click="resetSelected(gKey)"
                          >
                            <v-icon style="font-size: 14px">mdi-restore</v-icon>
                          </v-btn>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="groupComboItems(gKey).length > 0">
                      <tr
                        v-for="item in groupComboItems(gKey)"
                        :key="`s${item.id}`"
                        class="text-center"
                      >
                        <td style="width: 105px">{{ item.barcode }}</td>
                        <td>
                          <div
                            class="d-flex align-center"
                            v-for="(g, k) in item.goods"
                            :key="`cg_${k}_${g.id}`"
                          >
                            <div>
                              <div>
                                <span
                                  class="font-weight-medium"
                                  style="color: #b71c1c"
                                >
                                  {{ g.customer_goods_barcode }}
                                </span>
                                - {{ $t("labels.sku") }}: {{ g.sku }}
                                <span style="color: #b71c1c"
                                  >* {{ g.quantity }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>{{ formatNumber(item.available) }}</td>
                        <td style="width: 60px">
                          <v-text-field
                            v-model.number="item.quantity"
                            class="c-input-number-xs"
                            type="number"
                            @keyup="quantityAndDiscountChange"
                            dense
                            single-line
                            outlined
                            hide-details
                          ></v-text-field>
                        </td>
                        <td>{{ formatNumber(item[item.select_price]) }}</td>
                        <td>{{ priceType[item.select_price] }}</td>
                        <td>
                          <v-select
                            v-model="item.discount_type"
                            :items="discountTypeOptions"
                            class="c-input-small"
                            outlined
                            dense
                            hide-details
                            @change="quantityAndDiscountChange"
                            :disabled="
                              !checkPermission([
                                ,
                                'order_create_select_discount',
                              ])
                            "
                          ></v-select>
                        </td>
                        <td>
                          <template
                            v-if="
                              item.discount_type &&
                              checkPermission(['order_create_select_discount'])
                            "
                          >
                            <v-text-field
                              v-if="item.discount_type === 1"
                              v-model.number="item.discount_value"
                              max="100"
                              class="c-input-small"
                              type="number"
                              @keyup="quantityAndDiscountChange"
                              dense
                              single-line
                              outlined
                              hide-details
                            ></v-text-field>
                            <input-number
                              v-else
                              v-model.number="item.discount_value"
                              type="number"
                              @input="quantityAndDiscountChange"
                              outlined
                              dense
                              hide-details
                              class="c-input-small"
                            />
                          </template>
                        </td>
                        <td>
                          <v-btn
                            color="grey lighten-2"
                            x-small
                            style="min-width: 20px; height: 20px; padding: 0"
                            @click="removeSelected(item)"
                          >
                            <v-icon style="font-size: 14px">mdi-close</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td
                          colspan="9"
                          class="text-center vertical-align-middle"
                        >
                          {{ $t("labels.not_yet_select_goods") }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-card-text>
          </v-card>
        </div>

        <div v-else class="text-center font-italic py-3">
          {{ $t("labels.not_yet_select_goods") }}
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <v-card class="card-custom-shadow">
          <v-card-text>
            <v-row class="" dense>
              <v-col cols="12">
                <v-btn
                  color="success"
                  block
                  :disabled="isDisabledBtnSubmit"
                  @click="createOrder"
                  style="height: 40px"
                >
                  {{ $t("labels.confirm") }}
                </v-btn>
              </v-col>
              <v-col cols="12">
                <SelectSuggestReceiver
                  class="c-input-small"
                  :label="`${$t('labels.phone_number')} *`"
                  :default-value="receiver.receiver_phone_number"
                  name="receiver_phone_number"
                  name-type="phone"
                  @onChange="orderChange"
                  @onSelectSuggest="onSelectSuggest"
                />
              </v-col>
              <v-col cols="12">
                <SelectSuggestReceiver
                  class="c-input-small"
                  :label="`${$t('labels.name')} *`"
                  :default-value="receiver.receiver_name"
                  name="receiver_name"
                  name-type="name"
                  @onChange="orderChange"
                  @onSelectSuggest="onSelectSuggest"
                />
              </v-col>
              <v-col cols="12">
                <SelectCity
                  class="c-input-small"
                  name="id_city"
                  :id-city="receiver.id_city"
                  @onChange="orderChange"
                  ref="selectCityRef"
                />
              </v-col>
              <v-col cols="12">
                <SelectCounty
                  class="c-input-small"
                  :id-city="receiver.id_city"
                  :id-county="receiver.id_county"
                  name="id_county"
                  @onChange="orderChange"
                />
              </v-col>
              <v-col cols="12">
                <SelectWard
                  class="c-input-small"
                  :id-city="receiver.id_city"
                  :id-county="receiver.id_county"
                  :id-ward="receiver.id_ward"
                  name="id_ward"
                  @onChange="orderChange"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  class="c-input-small"
                  v-model="receiver.address"
                  :label="`${$t('labels.address')} *`"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="receiver.id_source"
                  :items="sources"
                  :label="$t('labels.source')"
                  outlined
                  dense
                  hide-details
                  class="c-input-small"
                  :class="{ 'input-error': isSourceRequired }"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <HistoryByPhone
          v-if="receiver.receiver_phone_number"
          :key="receiver.receiver_phone_number"
          :phone="receiver.receiver_phone_number"
          class="mt-5"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";
import { IMAGE_OPTIONS } from "@/libs/const";
import moment from "moment/moment";
import { compress } from "image-conversion";

export default {
  name: "CreateSingle",
  components: {
    SelectCity: () => import("@/components/common/SelectCity"),
    SelectCounty: () => import("@/components/common/SelectCounty"),
    SelectWard: () => import("@/components/common/SelectWard"),
    SelectSuggestReceiver: () =>
      import("@/components/common/SelectSuggestReceiver"),
    SelectSuggestGoodsWithPrice: () =>
      import("@/components/common/SelectSuggestGoodsWithPrice"),
    HistoryByPhone: () => import("@/components/orders/HistoryByPhone"),
  },
  data: () => ({
    isLoading: false,
    items: [],
    selectItems: [],
    priceType: {},
    payDeliveryCostOptions: [],
    deliveryTypeOptions: [],
    discountTypeOptions: [],
    discountOptions: [],
    deliveryCompanyOptions: [],
    bankAccountOptions: [],
    imageOptions: [...IMAGE_OPTIONS],
    receiver: {
      receiver_name: null,
      receiver_phone_number: null,
      id_city: null,
      id_county: null,
      id_ward: null,
      address: null,
      id_source: null,
    },
    orders: {},
    page: 1,
    totalPage: 1,
    filters: {},
    uploadingKey: null,
    defaultConfigs: [],
    isSourceRequired: false,
    sources: [],
  }),
  computed: {
    isDisabledBtnSubmit() {
      if (
        !this.receiver.receiver_name ||
        !this.receiver.receiver_phone_number ||
        !this.receiver.id_city ||
        !this.receiver.id_county ||
        !this.receiver.id_ward ||
        !this.receiver.address ||
        this.cod < 0
      ) {
        return true;
      }
      if (!this.selectItems || this.selectItems.length === 0) {
        return true;
      }
      if (!this.isPhoneValid(this.receiver.receiver_phone_number)) {
        return true;
      }
      if (this.isSourceRequired && !this.receiver.id_source) {
        return true;
      }
      const orderErrPrepay = this.orderConverted.filter(
        (ord) =>
          (ord.prepay && !ord.id_customer_bank_account) ||
          (ord.prepay && ord.bill_images.length === 0)
      );
      if (orderErrPrepay.length > 0) {
        return true;
      }
      return false;
    },
    selectedIds() {
      return [...this.selectItems].map(
        (s) => `${s.id}_${s.id_warehouse}_${s.id_pos}`
      );
    },
    groupItems() {
      const group = {};
      for (let i = 0; i < this.selectItems.length; i++) {
        const item = this.selectItems[i];
        if (!group[item.pos_code]) {
          group[item.pos_code] = [];
        }
        group[item.pos_code].push(item);
      }
      return group;
    },
    groupKeys() {
      return Object.keys(this.groupItems);
    },
    groupGoodsItems() {
      return (key) =>
        this.groupItems[key].filter((item) => item.item_type === "goods");
    },
    groupComboItems() {
      return (key) =>
        this.groupItems[key].filter((item) => item.item_type === "combo");
    },
    moneyOptions() {
      return {
        length: 11,
        precision: 0,
      };
    },
    goodsItems() {
      return (key) =>
        [...this.groupItems[key]].map((item) => ({
          barcode:
            item.item_type === "combo"
              ? item.barcode
              : item.customer_goods_barcode,
          quantity: item.quantity,
          base_price: item.base_price,
          bill_price: item.bill_price,
          price_type: item.select_price,
          discount_type: item.discount_type,
          discount_value: item.discount_value,
          discount_amount: item.discount_amount,
        }));
    },
    orderConverted() {
      const orders = [];
      for (let k = 0; k < this.groupKeys.length; k++) {
        const key = this.groupKeys[k];
        orders.push({
          ...this.receiver,
          ...this.orders[key],
          tt: k + 1,
          customer_order_id:
            this.orders[key].customer_order_id || this.generateRandomString(9),
          cash_on_delivery:
            this.cod(key) || this.cod(key) === 0
              ? this.cod(key)
              : this.totalPrice(key),
          goods_items: [...this.goodsItems(key)],
          discount_amount: this.discountAmount(key),
        });
      }
      return orders;
    },
  },
  watch: {
    /* page() {
      this.getList()
    },
    filters: {
      handler() {
        this.page = 1
        this.getList()
      },
      deep: true
    } */
  },
  created() {
    const priceType = {
      price: this.$t("labels.price"),
      wholesale_price: this.$t("labels.wholesale_price"),
    };
    const payDeliveryCostOptions = [
      {
        value: 0,
        text: this.$t("labels.delivery_payment_by_shop"),
      },
      {
        value: 1,
        text: this.$t("labels.delivery_payment_by_customer"),
      },
    ];
    const deliveryTypeOptions = [
      {
        value: 0,
        text: this.$t("labels.follow_delivery_config"),
      },
      {
        value: 1,
        text: this.$t("labels.tvc"),
      },
    ];
    const discountOptions = [
      {
        value: 0,
        text: this.$t("labels.no"),
      },
      {
        value: 1,
        text: this.$t("labels.discount_by_price"),
      },
      {
        value: 2,
        text: this.$t("labels.discount_by_percent"),
      },
    ];
    this.priceType = { ...priceType };
    this.payDeliveryCostOptions = [...payDeliveryCostOptions];
    this.deliveryTypeOptions = [...deliveryTypeOptions];
    this.discountOptions = [...discountOptions];
    this.discountTypeOptions = [
      {
        value: 1,
        text: "%",
      },
      {
        value: 2,
        text: "Tiền",
      },
    ];
  },
  mounted() {
    // this.getList()
    this.getDeliveryCompany();
    this.getBankAccounts();
    this.getDefaultConfigs();
    this.getSources();
  },
  methods: {
    async getDefaultConfigs() {
      const { data } = await httpClient.post("/default-config-active-list");
      this.defaultConfigs = [...data];
    },
    async getSources() {
      const { data } = await httpClient.post("/source-list", {
        status: 1,
        sort_by: "type",
        sort_type: "asc",
      });
      this.sources = [...data].map((item) => ({
        text: `${this.$t(`labels.source_type_${item.type}`)}: ${item.name}`,
        value: item.id,
      }));
    },
    onUploading(key) {
      this.uploadingKey = key;
    },
    async onUploadBill(files) {
      console.log(files);
      const uploaded = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file) {
          const resBlob = await compress(file, {
            quality: 0.85,
            type: "image/jpeg",
          });
          const newFile = new File(
            [resBlob],
            `${moment().unix()}_${this.generateRandomString(10)}.jpg`,
            { type: "image/jpeg" }
          );
          let fd = new FormData();
          fd.append("file", newFile);
          const { data } = await httpClient.post(`/upload-image`, fd);
          uploaded.push(data);
        }
      }
      this.orders[this.uploadingKey].bill_images = [...uploaded];
    },
    totalPrice(key) {
      let price = 0;
      this.groupItems[key].forEach((item) => {
        price += item.bill_price;
      });
      return price; //+(price >= 0 ? price : 0)
    },
    cod(key) {
      let price = this.totalPrice(key);
      price = price - (this.orders[key].prepay || 0);
      price = price + this.orders[key].add_ship_cost;
      /* if (!this.orders[key].tvc) {
        price = price + this.orders[key].add_ship_cost
      } */
      return price; //+(price >= 0 ? price : 0)
    },
    discountAmount(key) {
      let discount_amount = 0;
      this.groupItems[key].forEach((item) => {
        discount_amount += item.discount_amount;
      });
      return discount_amount;
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    getList: debounce(function () {
      httpClient
        .post("/goods-list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 1000),
    getBankAccounts() {
      httpClient
        .post("/get-all-bank-account", { status: 1 })
        .then(({ data }) => {
          this.bankAccountOptions = [...data].map((b) => ({
            value: b.id,
            text: `STK: ${b.account} - Chủ TK: ${b.owner}`,
          }));
        });
    },
    estimateDeliveryFee(key) {
      if (
        this.receiver.id_city &&
        this.receiver.id_county &&
        this.selectItems.length > 0 &&
        !this.orders[key].tvc
      ) {
        const order = this.orderConverted.find((o) => o.pos_code === key);
        httpClient
          .post("/order-estimate-delivery-fee", {
            order,
            id_city: this.receiver.id_county,
            id_county: this.receiver.id_county,
            id_pos: this.orders[key].id_pos,
          })
          .then(({ data }) => {
            if (
              data &&
              (data.id_delivery_company === 7 || !data.id_delivery_company)
            ) {
              this.orders[key].tvc = 1;
              this.onTvcChange(key);
            }
          });
      }
    },
    quantityAndDiscountChange: debounce(function () {
      this.selectItems = [...this.selectItems].map((item) => {
        const { base_price, bill_price, discount_amount } =
          this.calPriceItem(item);
        return {
          ...item,
          base_price,
          bill_price,
          discount_amount,
        };
      });
    }, 500),
    onCostAndPrepayChange: debounce(function () {
      const selectItems = [...this.selectItems];
      this.selectItems = [...selectItems];
    }, 500),
    orderChange(item) {
      this.receiver = { ...this.receiver, [item.name]: item.value };
      this.groupKeys.forEach((key) => {
        this.estimateDeliveryFee(key);
      });
    },
    deliveryChange(key) {
      this.estimateDeliveryFee(key);
    },
    onSelectSuggest(item) {
      const receiver = {};
      if (!this.receiver.receiver_name) {
        receiver.receiver_name = item.name;
      }
      if (!this.receiver.receiver_phone_number) {
        receiver.receiver_phone_number = item.phone;
      }
      if (!this.receiver.id_city) {
        receiver.id_city = item.id_city;
      }
      if (!this.receiver.id_county) {
        receiver.id_county = item.id_county;
      }
      if (!this.receiver.id_ward) {
        receiver.id_ward = item.id_ward;
      }
      if (!this.receiver.address) {
        receiver.address = item.address;
      }
      this.receiver = { ...this.receiver, ...receiver };
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    calPriceItem(item) {
      const base_price = item[item.select_price] * (item.quantity || 0);
      let bill_price = base_price;
      if (item.discount_type && item.discount_value) {
        if (item.discount_type === 1) {
          bill_price = base_price - (base_price * item.discount_value) / 100;
        } else {
          bill_price = base_price - item.discount_value;
        }
      }
      const discount_amount = base_price - bill_price;
      return {
        base_price,
        bill_price,
        discount_amount,
      };
    },
    selectItem({ item, type, item_type }) {
      item["select_price"] = type;
      item["item_type"] = item_type;
      item.quantity = 1;
      const { base_price, bill_price, discount_amount } =
        this.calPriceItem(item);
      item.base_price = base_price;
      item.bill_price = bill_price;
      item.discount_amount = discount_amount;
      if (!this.orders[item.pos_code]) {
        const config = [...this.defaultConfigs].find(
          (cf) => cf.id_pos === item.id_pos
        );
        this.isSourceRequired = (config && config.source_required) || false;
        this.orders[item.pos_code] = {
          id_pos: item.id_pos,
          pos_code: item.pos_code,
          note: (config && config.note) || null,
          tvc: 0,
          receiver_pay_for_delivery_cost: 0,
          id_delivery_company: null,
          delivery_order_id: null,
          add_ship_cost: (config && config.add_ship_cost) || 0,
          prepay: 0,
          id_customer_bank_account: null,
          file_bill: null,
          bill_images: [],
          customer_order_id: null,
          delivery_check_goods: (config && config.delivery_check_goods) || 0,
          delivery_partial: (config && config.delivery_partial) || 0,
          delivery_return_required:
            (config && config.delivery_return_required) || 0,
        };
      }
      this.selectItems.push(item);
      this.estimateDeliveryFee(item.pos_code);
    },
    onTvcChange(key) {
      const orders = { ...this.orders };
      orders[key].receiver_pay_for_delivery_cost = 0;
      orders[key].id_delivery_company = null;
      orders[key].delivery_order_id = null;
      orders[key].delivery_check_goods = null;
      orders[key].delivery_partial = null;
      orders[key].delivery_return_required = null;
      orders[key].add_ship_cost = 0;
      this.orders = { ...orders };
    },
    onReceiverPayCostChange(key) {
      const orders = { ...this.orders };
      orders[key].add_ship_cost = 0;
      this.orders = { ...orders };
    },
    removeSelected(item) {
      this.selectItems = [...this.selectItems].filter(
        (s) =>
          `${s.id}_${s.id_warehouse}_${s.id_pos}` !==
          `${item.id}_${item.id_warehouse}_${item.id_pos}`
      );
    },
    resetSelected(key) {
      this.selectItems = [...this.selectItems].filter(
        (s) => s.pos_code !== key
      );
    },
    async getDeliveryCompany() {
      const { data } = await httpClient.post("/get-delivery-company");
      this.deliveryCompanyOptions = data.map((e) => ({
        value: e.id,
        text: this.capitalizeStr(e.name) || "",
      }));
    },
    async createOrder() {
      if (this.isDisabledBtnSubmit) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      try {
        const items = this.orderConverted;
        let fd = new FormData();
        fd.append("orders", JSON.stringify(items));
        fd.append("create_method", 1);
        await httpClient.post("/orders-create", fd);
        this.selectItems = [];
        this.orders = {};
        this.receiver = {};
        this.$refs.selectCityRef.resetValue();
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.create_success"));
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        const validates = (e.response &&
          e.response.data &&
          e.response.data.error &&
          e.response.data.error.validates) || { errRows: [], errTexts: [] };
        if (
          validates &&
          validates.errGroups &&
          validates.errGroups[0] &&
          validates.errGroups[0].errors
        ) {
          const errors = validates.errGroups[0].errors || [];
          this.$vToastify.error(errors.join("; "));
        } else {
          this.$vToastify.error(errMsg);
        }
      }
    },
  },
};
</script>

<style scoped></style>
